/* Obvious Imports */

import { createTheme } from "@mui/material/styles";

/*

Our theme is going to use custom breakpoints and the @mui/material/styles module needs to be augmented to accommodate the new types we are going to define.

*/

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    xsPlus: true;
    sm: true;
    md: true;
    lg: true;
    lgPlus: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

/*

Our theme is going to use a custom color palette but we have not defined any new types yet, so there is no need for module augmentation. However, Before beginning frontend development it might be wise to define some custom colors in our palette.

*/

const Theme = createTheme({
  palette: {
    primary: {
      main: "#0DB14B",
      light: "#5be479",
      dark: "#00801d",
    },
    secondary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#CCCCCC",
    },
    error: {
      main: "#F44336",
      light: "#ff7961",
      dark: "#ba000d",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"DM Sans", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 280.05,
      xsPlus: 540,
      sm: 600,
      md: 960,
      lg: 1280,
      lgPlus: 1366,
      xl: 1920,
      xxl: 2560,
      xxxl: 3840,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          display: "none",
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#0DB14B",
          background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }
      }
    },
  },
});

export default Theme;