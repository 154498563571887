/* Common Imports */

import React from "react";
import PropTypes from "prop-types";

/* Vercel Analytics Imports */

import { Analytics } from "@vercel/analytics/react";

/* Global CSS Imports */

import "../public/fonts/dm-sans/dm-sans.css";
import "../public/styles/index.css";

/* Theme Imports */

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from '@emotion/react';
import CreateEmotionCache from "../components/layout-components/CreateEmotionCache";
import Theme from "../components/layout-components/Theme";

export function reportWebVitals(metric: any) {
  // console.log(metric); /* TODO: Collect metrics! */
}

const clientSideEmotionCache = CreateEmotionCache();

const Motherlod = (props: { Component: any; emotionCache?: EmotionCache | undefined; pageProps: any; }) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <React.Fragment>
        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={Theme}>
            <CssBaseline />
          <Component {...pageProps} />
          <Analytics />
          </ThemeProvider>
        </CacheProvider>
    </React.Fragment>
  );
}

Motherlod.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

export default Motherlod;